/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  const reg = /^1[3456789]\d{9}$/;
  return reg.test(s)
}

/**
 * 判断手机号码是否正确
 */
export function isvalidatemobile(phone) {
  let res = {
    result: false,
    msg: ''
  };
  if (!validatenull(phone)) {
    if (phone.length == 11) {
      if (!isMobile(phone)) {
        res.msg = '手机号格式不正确';
      } else {
        res.result = true;
      }
    } else {
      res.msg = '手机号格式不正确';
    }
  } else {
    res.msg = '请输入手机号';
  }
  return res;
}

/**
 * 判断是否为空
 */
export function validatenull(val) {
  if (typeof val == 'boolean') {
    return false;
  }
  if (typeof val == 'number') {
    return false;
  }
  if (val instanceof Array) {
    if (val.length == 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true;
  } else {
    if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true;
    return false;
  }
  return false;
}

/**
 * 判断是否为整数
 */
export function validatenum(num, type) {
  let regName = /[^\d.]/g;
  if (type == 1) {
      if (!regName.test(num)) return false;
  } else if (type == 2) {
      regName = /[^\d]/g;
      if (!regName.test(num)) return false;
  }
  return true;
}
