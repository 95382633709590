import { get, post } from '@/http/axios';

import { baseUrl } from '@/config/website';

export const checkToken = () => get(baseUrl + '/backstage/validated/exter/person/info-all', { hasToken: true });

export const getBanner = (page) => post(baseUrl + '/backstage/exter/banner/list', { page });

export const doDesign = (kujialeAppuid) => get(baseUrl + `/backstage/validated/exter/iframeSrc/${kujialeAppuid}`, { hasToken: true });

export const sendMsg = (data) => post(baseUrl + '/backstage/exter/order/submitOrder', data);

export const homeInfo = () => post(baseUrl + '/backstage/exter/back/home/info', {});

export const coreInfo = () => get(baseUrl + '/backstage/exter/back/core/info');

export const bottomInfo = () => get(baseUrl + '/backstage/exter/back/bottom/info');

export const getVideoCate = () => get(baseUrl + '/backstage/exter/video/classify/list');
