import Router from 'vue-router';
import routes from './routes';
import store from '../store';

const mode = 'hash' // hash | history
const createRouter = function () {
  return new Router({
    mode,
    base: mode === 'hash' ? '/' : process.env.BASE_URL,
  })
}

export const router = createRouter()
export const resetRoutes = function (newRoutes) {
  router.matcher = createRouter().matcher
  router.addRoutes(newRoutes)
}
/**
 * @param {(meta:Object,route:Object)=>boolean} filterCallback
 * @returns {Array}
 */
export const filterMapRoutes = function (filterCallback) {
  const loop = curRoutes =>
    curRoutes
      .filter(route => filterCallback(route.meta || {}, route))
      .map(({ children, ...newRoute }) => {
        if (children) newRoute.children = loop(children)
        return newRoute
      })
  return loop(routes)
}
export default router

/* 初始化公共路由 */
resetRoutes(
  filterMapRoutes(meta => {
    return meta.rules === undefined // 如何处理路由权限因项目而异...
  }),
)

router.beforeEach((to, from, next) => {
  store.commit('clearToken'); // 取消请求
  if (to.name === 'login' && to.params.back) {
    store.dispatch("SetBack", to.params.back).then(() => { });
  } else {
    if (!from.meta.requireAuth) {
      store.dispatch("SetBack", { name: from.name, params: from.params, query: from.query }).then(() => { });
    }
  }
  const token = store.getters.token;
  if (to.meta.requireAuth || to.meta.user == undefined) {
    // if (token !== '') {
    //   store.dispatch("CheckToken")
    //     .then(() => { })
    //     .catch(() => {
    //     });
    // }
    next();
  } else {
    if (token === '') {
      next({ name: 'login' })
    } else {
      // const typeChange = to.query.type !== undefined || from.query.type !== undefined;
      // if (to.name != from.name || typeChange) {
      //   store.dispatch("CheckToken")
      //     .then(() => { })
      //     .catch(() => {
      //       next({ name: 'login' })
      //     });
      // }
      next();
    }
  }
})

router.afterEach((to) => {
  const title = to.meta.title;
  if (title) {
    document.title = title;
  }
  if (!to.params.alive) {
    window.scrollTo(0, 0);
  }

})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
