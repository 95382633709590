import axios from 'axios'
import store from '@/store';
import router from '@/router';

axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

//HTTPrequest拦截
axios.interceptors.request.use((config) => {
  config.cancelToken = new axios.CancelToken(function (cancel) {
    store.commit('pushToken', { cancelToken: cancel })
  })
  let flag = false;
  const token = store.getters.token;
  if (token) {
    flag = false;
  }
  if (config.data && config.data.hasToken) {
    flag = true;
    delete config.data.hasToken;
  }
  if (config.params && config.params.hasToken) {
    flag = true;
    delete config.params.hasToken;
  }
  if (flag) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config
}, error => {
  return Promise.reject(error)
});

// 响应拦截器
axios.interceptors.response.use(response => {
  const code = response.data.code;
  if (code === 401) {
    store.dispatch("LogOut").then(() => {
      router.push({ name: "login" });
    });
  }
  if (response.status === 200) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
}, error => {
  return Promise.reject(error);
})

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    })
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      })
  });
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      })
  });
}
