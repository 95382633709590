export const routes = [{
  path: '/',
  name: 'home',
  meta: { title: '商装宝-首页' },
  component: () =>
    import('@/views/Home/Home')
}, {
  path: '/login',
  name: 'login',
  meta: { title: '商装宝-账号登录', requireAuth: true },
  component: () =>
    import('@/views/Login/Login')
}, {
  path: '/phone_login',
  name: 'phone_login',
  meta: { title: '商装宝-手机登录', requireAuth: true },
  component: () =>
    import('@/views/Login/Login')
}, {
  path: '/register',
  name: 'register',
  meta: { title: '商装宝-手机注册', requireAuth: true },
  component: () =>
    import('@/views/Login/Login')
}, {
  path: '/wx_register',
  name: 'wx_register',
  meta: { title: '商装宝-微信注册', requireAuth: true },
  component: () =>
    import('@/views/Login/Login')
}, {
  path: '/dodesign',
  name: 'dodesign',
  meta: { title: '3D云设计' },
  component: () =>
    import('@/views/Dodesign')
}, {
  path: '/core',
  name: 'core',
  meta: { title: '核心服务' },
  component: () =>
    import('@/views/Core/Core')
}, {
  path: '/designer',
  name: 'designer',
  meta: { title: '找设计师' },
  component: () =>
    import('@/views/Designer/Designer')
}, {
  path: '/designer/:id',
  name: 'designerDetail',
  meta: { title: '设计师-详情' },
  component: () =>
    import('@/views/Designer/DesignerDetail')
}, {
  path: '/store',
  name: 'store',
  meta: { title: '4S门店' },
  component: () =>
    import('@/views/Store/Store')
}, {
  path: '/store/:id',
  name: 'storeDetail',
  meta: { title: '4S门店-详情' },
  component: () =>
    import('@/views/Store/StoreDetail')
},
{
  path: '/case',
  name: 'case',
  meta: { title: '案例库' },
  component: () =>
    import('@/views/Case/Case')
},
{
  path: '/case_map',
  name: 'caseMap',
  meta: { title: '案例库-后台映射' },
  component: () =>
    import('@/views/Case/CaseMap')
},
{
  path: '/case/:id',
  name: 'caseDetail',
  meta: { title: '案例库-详情' },
  component: () =>
    import('@/views/Case/CaseDetail')
},
{
  path: '/case_map/:id',
  name: 'caseDetailMap',
  meta: { title: '案例库-后台映射-详情' },
  component: () =>
    import('@/views/Case/CaseDetail_map')
},
{
  path: '/product',
  name: 'product',
  meta: { title: '产品库' },
  component: () =>
    import('@/views/Product/Product')
}, {
  path: '/product_map',
  name: 'productMap',
  meta: { title: '产品库-后台映射' },
  component: () =>
    import('@/views/Product/ProductMap')
}, {
  path: '/product/:id',
  name: 'productDetail',
  meta: { title: '产品库-详情' },
  component: () =>
    import('@/views/Product/ProductDetail')
},
{
  path: '/product_map/:id',
  name: 'productDetailMap',
  meta: { title: '产品库-后台映射-详情' },
  component: () =>
    import('@/views/Product/ProductDetail')
},
{
  path: '/guarantee',
  name: 'guarantee',
  meta: { title: '品质保障' },
  component: () =>
    import('@/views/Guarantee/Guarantee')
},
{
  path: '/guarantee/:id',
  name: 'guaranteeDetail',
  meta: { title: '品质保障-详情' },
  component: () =>
    import('@/views/Guarantee/GuaranteeDetail')
},
{
  path: '/exchange',
  name: 'exchange',
  meta: { title: '行业资源' },
  component: () =>
    import('@/views/Exchange/Exchange')
},
{
  path: '/exchange/:id',
  name: 'exchangeDetail',
  meta: { title: '行业资源-详情' },
  component: () =>
    import('@/views/Exchange/ExchangeDetail')
},
{
  path: '/video',
  name: 'video',
  meta: { title: '视频展示' },
  component: () =>
    import('@/views/VideoDisplay/VideoDisplay')
},
{
  path: '/about',
  name: 'about',
  meta: { title: '关于我们' },
  component: () =>
    import('@/views/About/About')
},
{
  path: '/project',
  name: 'projectCloud',
  meta: { title: '项目云管理' },
  component: () =>
    import('@/views/ProjectCloud/ProjectCloud')
},
{
  path: '/project/:id',
  name: 'projectBackstage',
  meta: { title: '项目云管理后台' },
  component: () =>
    import('@/views/ProjectCloud/Backstage')
},
{
  path: '/user/profile',
  name: 'userProfile',
  meta: { title: '个人中心-设计师资料', user: true },
  component: () =>
    import('@/views/User/Profile')
},
{
  path: '/user/plan',
  name: 'userPlan',
  meta: { title: '个人中心-我的方案', user: true },
  component: () =>
    import('@/views/User/Plan')
},
{
  path: '/user/collect',
  name: 'userCollect',
  meta: { title: '个人中心-我的收藏' },
  component: () =>
    import('@/views/User/Collect')
},
{
  path: '/user/exchange',
  name: 'userExchange',
  meta: { title: '个人中心-行业材料上传', user: true },
  component: () =>
    import('@/views/User/Exchange')
},
{
  path: '/user/password',
  name: 'userPassword',
  meta: { title: '个人中心-修改密码', user: true },
  component: () =>
    import('@/views/User/Password')
},
{
  path: '/URL',
  name: 'URL',
  meta: { title: '常用链接' },
  component: () =>
    import('@/views/URL/URL')
}
]



export default routes
