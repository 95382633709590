import { get, post } from '@/http/axios';

import { baseUrl } from '@/config/website';

export const getExchangeList = (data) => post(baseUrl + '/backstage/exter/exchange/list', data);

export const getExchangeDetail = (id) => get(baseUrl + '/backstage/exter/exchange/' + id);

export const getVideoList = (data) => post(baseUrl + '/backstage/exter/video/list', data);

export const getExchangeCate = () => get(baseUrl + '/backstage/exter/exchange/classify/list');

export const exchangeSubmit = (data) => post(baseUrl + '/backstage/exter/exchange/submit', data);
