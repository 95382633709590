<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      timer: "",
    };
  },
  computed: {},
  mounted() {
    this.$store.dispatch("setCore");
    this.$store.dispatch("setExchange");
    this.$store.dispatch("setVideoCate");
    this.$store.dispatch("setFooter");
  },
  methods: {},
  destroyed() {},
};
</script>

<style lang="scss">
#app {
  min-width: $minWidth;
  background-color: $bgColor;
}
</style>
