import { setStore, getStore } from "@/util/store";
import { loginByUsername } from "@/api/user";
import { checkToken, coreInfo, bottomInfo, getVideoCate } from "@/api/common";
import router from '../../router';
import { getExchangeCate } from "@/api/exchange";

const user = {
  state: {
    token: getStore({ name: 'token' }) || '',
    userInfo: getStore({ name: 'userInfo' }) || {},
    remPassword: getStore({ name: 'remPassword' }) || {},
    routerBack: getStore({ name: 'routerBack' }) || {},
    login_bg: getStore({ name: 'login_bg' }) || '',
    core: getStore({ name: 'core' }) || [],
    exchange: getStore({ name: 'exchange' }) || [],
    videoCate: getStore({ name: 'videoCate' }) || [],
    footer_img: getStore({ name: 'footer_img' }) || '',
    cancelTokenArr: [] // 取消请求token数组
  },
  actions: {
    // 根据用户名登录
    LoginByUsername({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        const rem = Object.assign({}, userInfo);
        delete userInfo.checked;
        loginByUsername(userInfo).then(res => {
          if (res.code == 200) {
            commit('SET_TOKEN', res.token);
            if (rem.loginType == 0) {
              commit('SET_REMPASSWORD', rem);
            }
            resolve(res);
          } else {
            resolve(res);
          }
        }).catch(error => {
          reject(error);
        })
      })
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '');
        commit('SET_USERINFO', {});
        resolve();
      })
    },
    // 检查token
    CheckToken({ state, commit }) {
      return new Promise((resolve, reject) => {
        const token = state.token;
        if (token === '') {
          reject();
        } else {
          checkToken().then(res => {
            if (res.code == 200) {
              const data = res.data;
              let res_user = {};
              if (data.accountType == 0) {
                res_user = {
                  ...data.account,
                  accountType: 0
                }
                commit("SET_USERINFO", res_user);
                resolve(res);
              } else if (data.accountType == 1) {
                if (router.history.current.meta.user) {
                  router.push({ name: 'home' });
                }
                const tourist = data.tourist || {};
                res_user = {
                  ...tourist,
                  account: tourist.phonenumber,
                  accountId: tourist.touristId,
                  accountType: 1
                }
                commit("SET_USERINFO", res_user);
                resolve(res);
              } else {
                commit('SET_TOKEN', '');
                commit('SET_USERINFO', {});
                reject(res);
              }
            } else {
              commit('SET_TOKEN', '');
              commit('SET_USERINFO', {});
              reject(res);
            }
          })
        }
      })
    },
    setCore({ commit }) {
      return new Promise((resolve) => {
        coreInfo().then(res => {
          if (res.code == 200) {
            let core = [];
            const list = res.data.sysCoreDownList;
            for (let i = 0; i < list.length; i++) {
              const item = { url: list[i].coreDownId, title: list[i].coreDownTitle };
              if (list[i].coreDownTitle == '商业空间设计') {
                core.unshift(item);
              } else {
                core.push(item);
              }
            }
            commit("SET_CORE", core);
          }
          resolve(res);
        })
      })
    },
    setExchange({ commit }) {
      return new Promise((resolve) => {
        getExchangeCate().then(res => {
          if (res.code == 200) {
            const list = res.rows;
            let data = [];
            for (let i = 0; i < list.length; i++) {
              data.push(
                { url: list[i].classifyId, title: list[i].classifyName },
              )
            }
            commit("SET_EXCHANGE", data);
          }
          resolve(res);
        })
      })
    },
    setVideoCate({ commit }) {
      return new Promise((resolve) => {
        getVideoCate().then(res => {
          if (res.code == 200) {
            const list = res.rows;
            let data = [];
            for (let i = 0; i < list.length; i++) {
              data.push(
                { url: list[i].classifyId, title: list[i].classifyName },
              )
            }
            commit("SET_VIDEOCATE", data);
          }
          resolve(res);
        })
      })
    },
    setFooter({ commit }) {
      return new Promise((resolve) => {
        bottomInfo().then(res => {
          if (res.code == 200) {
            commit("SET_FOOTER", res.data.bottomPicture);
          }
          resolve(res);
        })
      })
    },
    // 设置返回路由
    SetBack({ commit }, routerBack) {
      return new Promise((resolve) => {
        commit('SET_BACK', routerBack);
        resolve();
      })
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      setStore({ name: 'token', content: state.token })
    },
    SET_USERINFO: (state, userinfo) => {
      state.userInfo = userinfo;
      setStore({ name: 'userInfo', content: state.userInfo })
    },
    SET_REMPASSWORD: (state, remPassword) => {
      state.remPassword = remPassword;
      setStore({ name: 'remPassword', content: state.remPassword })
    },
    SET_BACK: (state, routerBack) => {
      state.routerBack = routerBack;
      setStore({ name: 'routerBack', content: state.routerBack })
    },
    SET_LOGIN_BG: (state, login_bg) => {
      state.login_bg = login_bg;
      setStore({ name: 'login_bg', content: state.login_bg })
    },
    SET_CORE: (state, core) => {
      state.core = core;
      setStore({ name: 'core', content: state.core })
    },
    SET_EXCHANGE: (state, exchange) => {
      state.exchange = exchange;
      setStore({ name: 'exchange', content: state.exchange })
    },
    SET_VIDEOCATE: (state, videoCate) => {
      state.videoCate = videoCate;
      setStore({ name: 'videoCate', content: state.videoCate })
    },
    SET_FOOTER: (state, footer_img) => {
      state.footer_img = footer_img;
      setStore({ name: 'footer_img', content: state.footer_img })
    },
    pushToken(state, payload) {
      state.cancelTokenArr.push(payload.cancelToken)
    },
    clearToken({ cancelTokenArr }) {
      cancelTokenArr.forEach(item => {
        item('路由跳转取消请求')
      })
      cancelTokenArr = []
    }
  }

}
export default user