import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import './styles/common.scss'

import "swiper/dist/css/swiper.min.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);

import {
  Pagination,
  Dialog,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  Select,
  Option,
  Button,
  Tooltip,
  Form,
  FormItem,
  Icon,
  Loading,
  Message,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Popover,
  Cascader,
  Tag,
  CheckboxGroup,
  Checkbox,
  MessageBox,
  Backtop,
  Upload,
  Image,
  Table,
  TableColumn,
} from 'element-ui';
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Popover);
Vue.use(Cascader);
Vue.use(Tag);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Backtop);
Vue.use(Upload);
Vue.use(Image);
Vue.use(Table);
Vue.use(TableColumn);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
