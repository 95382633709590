const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  remPassword: state => state.user.remPassword,
  routerBack: state => state.user.routerBack,
  login_bg: state => state.user.login_bg,
  core: state => state.user.core,
  exchange: state => state.user.exchange,
  videoCate: state => state.user.videoCate,
  footer_img: state => state.user.footer_img,
}
export default getters
